import { ApiServiceWithSettings, Audience, Authentication } from '../../common'
import { AxiosPromise } from 'axios'
import { Pagination, Pricing } from '@one/types'
import CampaignEffectLabels = Pricing.CampaignEffectLabels
import CampaignConditionLabels = Pricing.CampaignConditionLabels
import PromoPointRestView = Pricing.PromoPointRestView

export class BackofficePricingApi extends ApiServiceWithSettings {
  getAdditionalCostTypes(): AxiosPromise<Array<Pricing.AdditionalCostTypeDto>> {
    return this.get('/additional-costs/types', {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE,
      cache: {
        tags: ['additional-cost-types']
      }
    })
  }
  createOrUpdateAdditionalCostType(
    additionalCostTypes: Array<Pricing.AdditionalCostTypeDto>
  ): AxiosPromise<void> {
    this.invalidate(['additional-cost-types'])
    return this.put('/additional-costs/types', additionalCostTypes, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
  getCouponsPoolList(
    pageNumber: number,
    pageSize: number,
    query: string
  ): AxiosPromise<Pagination.Pager<Pricing.PoolDto>> {
    return this.get('/coupon-pools', {
      params: {
        pageNumber,
        pageSize,
        query
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE,
      cache: {
        tags: ['coupon-pools']
      }
    })
  }
  createCouponPool(
    poolForm: Pricing.PoolForm
  ): AxiosPromise<Pricing.PoolCreated> {
    this.invalidate(['coupon-pools'])
    return this.post('/coupon-pools', poolForm, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
  getCouponPool(poolId: string): AxiosPromise<Pricing.PoolDto> {
    return this.get(`/coupon-pools/${poolId}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE,
      cache: {
        tags: ['coupon-pool']
      }
    })
  }
  updateCouponPool(
    poolForm: Pricing.PoolForm,
    poolId: string
  ): AxiosPromise<void> {
    this.invalidate(['coupon-pools'])
    return this.put(`/coupon-pools/${poolId}`, poolForm, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
  getCouponsList(
    pageNumber: number,
    pageSize: number,
    poolId: string,
    query: string
  ): AxiosPromise<Pagination.Pager<Pricing.CouponDto>> {
    return this.get(`/coupon-pools/${poolId}/coupons`, {
      params: {
        pageNumber,
        pageSize,
        query
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE,
      cache: {
        tags: ['coupons']
      }
    })
  }
  importCoupons(
    file: FormData,
    poolId: string
  ): AxiosPromise<Pricing.ImportSucceed> {
    this.invalidate(['coupons'])
    return this.put(`/coupon-pools/${poolId}/coupons/import`, file, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
  deleteCoupon(
    couponDeleteForm: Pricing.CouponDeleteForm,
    poolId: string
  ): AxiosPromise<void> {
    this.invalidate(['coupons'])
    return this.delete(`/coupon-pools/${poolId}/coupons`, {
      data: couponDeleteForm,
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
  addCoupon(
    couponForm: Pricing.CouponForm,
    poolId: string
  ): AxiosPromise<void> {
    this.invalidate(['coupons'])
    return this.put(`/coupon-pools/${poolId}/coupons`, couponForm, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
  getCampaignsList(
    pageNumber: number,
    pageSize: number
  ): AxiosPromise<Pagination.Pager<Pricing.CampaignDto>> {
    return this.get('/campaigns', {
      params: {
        pageNumber,
        pageSize
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE,
      cache: {
        tags: ['campaigns']
      }
    })
  }
  createCampaign(
    campaignForm: Pricing.CampaignCreateForm
  ): AxiosPromise<Pricing.CampaignCreated> {
    this.invalidate(['campaigns'])
    return this.post('/campaigns', campaignForm, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
  getCampaign(campaignId: string): AxiosPromise<Pricing.CampaignDto> {
    return this.get(`/campaigns/${campaignId}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE,
      cache: {
        tags: ['campaigns']
      }
    })
  }
  getCampaignsActiveForClient(
    clientId: string,
    mainSegment?: string
  ): AxiosPromise<Pricing.CampaignsActiveForClientResponse> {
    return this.get(`/campaigns/active-for-client`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE,
      params: {
        clientId,
        mainSegment
      }
    })
  }
  updateCampaign(
    campaignForm: Pricing.CampaignChangeForm,
    campaignId: string
  ): AxiosPromise<void> {
    this.invalidate(['campaigns'])
    return this.put(`/campaigns/${campaignId}`, campaignForm, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
  activateCampaign(campaignId: string): AxiosPromise<void> {
    this.invalidate(['campaigns'])
    return this.put(
      `/campaigns/${campaignId}/activate`,
      {},
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }
  disableCampaign(campaignId: string): AxiosPromise<void> {
    this.invalidate(['campaigns'])
    return this.put(
      `/campaigns/${campaignId}/disable`,
      {},
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }
  // TODO: Remove this method after conditions refactor
  updateConditions(
    conditionsForm: Pricing.CampaignConditionsForm,
    campaignId: string
  ): AxiosPromise<void> {
    this.invalidate(['campaigns'])
    return this.put(`/campaigns/${campaignId}/conditions`, conditionsForm, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
  createCondition(
    conditionCreateForm: Pricing.CampaignConditionsForm,
    campaignId: string
  ): AxiosPromise<Pricing.ConditionCreated> {
    this.invalidate(['campaigns'])
    return this.post(
      `/campaigns/${campaignId}/conditions`,
      conditionCreateForm,
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }
  getConditions(
    campaignId: string
  ): AxiosPromise<Pricing.CampaignConditionsDto> {
    this.invalidate(['campaigns'])
    return this.get(`/campaigns/${campaignId}/conditions`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  changeCondition(
    changeConditionForm: Pricing.CampaignConditionsForm,
    campaignId: string,
    conditionId: string
  ): AxiosPromise<void> {
    this.invalidate(['campaigns'])
    return this.patch(
      `/campaigns/${campaignId}/conditions/${conditionId}`,
      changeConditionForm,
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }

  removeCondition(campaignId: string, conditionId: string): AxiosPromise<void> {
    this.invalidate(['campaigns'])
    return this.delete(`/campaigns/${campaignId}/conditions/${conditionId}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  importConditionProducts(
    campaignId: string,
    conditionId: string,
    file: File
  ): AxiosPromise<void> {
    this.invalidate(['campaigns'])
    return this.put(
      `/campaigns/${campaignId}/conditions/${conditionId}/products/import`,
      file,
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }
  exportConditionProducts(
    campaignId: string,
    conditionId: string
  ): AxiosPromise<void> {
    this.invalidate(['campaigns'])
    return this.get(
      `/campaigns/${campaignId}/conditions/${conditionId}/products/export`,
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }
  getConditionLabels(): AxiosPromise<CampaignConditionLabels> {
    return this.get(`/campaigns/condition-labels`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
  getCampaignEffectLabels(): AxiosPromise<CampaignEffectLabels> {
    return this.get(`/campaigns/effect-labels`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
  updateCampaignEffects(
    campaignId: string,
    effectsForm: Pricing.EffectConditionForm
  ): AxiosPromise<void> {
    this.invalidate(['campaigns'])
    return this.put(
      `/campaigns/${campaignId}/effects`,
      { effects: effectsForm },
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }
  importEffectProducts(
    campaignId: string,
    effectId: string,
    file: File
  ): AxiosPromise<void> {
    this.invalidate(['campaigns'])
    return this.put(
      `/campaigns/${campaignId}/effects/${effectId}/products/import`,
      file,
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }
  exportEffectProducts(
    campaignId: string,
    effectId: string
  ): AxiosPromise<void> {
    this.invalidate(['campaigns'])
    return this.get(
      `/campaigns/${campaignId}/effects/${effectId}/products/export`,
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }
  moveEffectUp(campaignId: string, effectId: string): AxiosPromise<void> {
    this.invalidate(['campaigns'])
    return this.post(
      `/campaigns/${campaignId}/effects/${effectId}/move-up`,
      {},
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }
  moveEffectDown(campaignId: string, effectId: string): AxiosPromise<void> {
    this.invalidate(['campaigns'])
    return this.post(
      `/campaigns/${campaignId}/effects/${effectId}/move-down`,
      {},
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }
  moveCampaignUp(campaignId: string): AxiosPromise<void> {
    this.invalidate(['campaigns'])
    return this.put(
      `/campaigns/${campaignId}/move-up`,
      {},
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }
  moveCampaignDown(campaignId: string): AxiosPromise<void> {
    this.invalidate(['campaigns'])
    return this.put(
      `/campaigns/${campaignId}/move-down`,
      {},
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }
  getPromoPoints(clientId: string): AxiosPromise<Array<PromoPointRestView>> {
    return this.get(`/promo-points/${clientId}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
  getProductsPrices(
    products: Array<Pricing.ProductRequest>,
    clientId?: string,
    segmentId?: string
  ): AxiosPromise<Array<Pricing.ProductPriceDto>> {
    return this.post(
      '/get-price',
      {
        products,
        clientId,
        segmentId
      },
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE,
        cache: {
          tags: ['product-prices'],
          ttl: 5000
        }
      }
    )
  }

  createEffect(
    effectCreateForm: Pricing.CampaignEffectCreateForm,
    campaignId: string
  ): AxiosPromise<Pricing.EffectCreated> {
    this.invalidate(['campaigns'])
    return this.post(`/campaigns/${campaignId}/effects`, effectCreateForm, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getEffects(campaignId: string): AxiosPromise<Pricing.CampaignEffectsDto> {
    this.invalidate(['campaigns'])
    return this.get(`/campaigns/${campaignId}/effects`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  changeEffect(
    changeEffectForm: Pricing.CampaignEffectChangeForm,
    campaignId: string,
    effectId: string
  ): AxiosPromise<void> {
    this.invalidate(['campaigns'])
    return this.patch(
      `/campaigns/${campaignId}/effects/${effectId}`,
      changeEffectForm,
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }

  removeEffect(campaignId: string, effectId: string): AxiosPromise<void> {
    this.invalidate(['campaigns'])
    return this.delete(`/campaigns/${campaignId}/effects/${effectId}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getAwards(
    campaignId: string,
    effectId: string,
    pageNumber: number,
    pageSize: number
  ): AxiosPromise<Pricing.PagerAwardProductDto> {
    this.invalidate(['campaigns'])
    return this.get(`/campaigns/${campaignId}/effects/${effectId}/awards`, {
      params: {
        pageNumber,
        pageSize
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  addAwards(
    awards: Pricing.AwardsAddForm,
    campaignId: string,
    effectId: string
  ): AxiosPromise<void> {
    this.invalidate(['campaigns'])
    return this.post(
      `/campaigns/${campaignId}/effects/${effectId}/awards`,
      { awards },
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }

  changeAwards(
    awards: Pricing.AwardsChangeForm,
    campaignId: string,
    effectId: string
  ): AxiosPromise<void> {
    this.invalidate(['campaigns'])
    return this.patch(
      `/campaigns/${campaignId}/effects/${effectId}/awards`,
      { awards },
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }

  removeAward(
    campaignId: string,
    effectId: string,
    awardId: string
  ): AxiosPromise<void> {
    this.invalidate(['campaigns'])
    return this.delete(
      `/campaigns/${campaignId}/effects/${effectId}/awards/${awardId}`,
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }

  changeAllAwardsConfiguration(
    campaignAwardsConfigurationChangeForm: Pricing.CampaignAwardsConfigurationChangeForm,
    campaignId: string,
    effectId: string
  ): AxiosPromise<void> {
    this.invalidate(['campaigns'])
    return this.put(
      `/campaigns/${campaignId}/effects/${effectId}/awards/configuration`,
      campaignAwardsConfigurationChangeForm,
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }

  getAllAwardProductIds(
    campaignId: string,
    effectId: string
  ): AxiosPromise<Pricing.AwardProductIds> {
    this.invalidate(['campaigns'])
    return this.get(
      `/campaigns/${campaignId}/effects/${effectId}/awards/products`,
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }

  getCampaignConditionsIdentities(
    campaignId: string,
    filterTypes: Pricing.ConditionTypes[]
  ): AxiosPromise<Pricing.AwardProductIds> {
    this.invalidate(['campaigns'])
    return this.get(`/campaigns/${campaignId}/conditions/identities`, {
      params: {
        filterTypes
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  freezePrices(
    calculationId: string,
    body: Pricing.PriceFreezeRequest
  ): AxiosPromise<void> {
    return this.post(`/price-calculation/${calculationId}/freeze`, body, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  unfreezePrices(calculationId: string): AxiosPromise<void> {
    return this.delete(`/price-calculation/${calculationId}/freeze`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getCalculationSettings(
    calculationId: string
  ): AxiosPromise<Pricing.PriceCalculationSettingsView> {
    return this.get(`/price-calculation/${calculationId}/settings`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  fetchCampaignsExplain(explainData: Pricing.ExplainData) {
    return this.post(
      '/campaigns/calculate-prices/explain',
      { ...explainData },
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }

  createCampaignDuplicate(
    campaignId: string
  ): AxiosPromise<Pricing.CampaignCreated> {
    return this.put(
      `/campaigns/${campaignId}/duplicate`,
      {},
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }

  importProductsIntoCondition(
    campaignId: string,
    conditionId: string,
    productIdentities: Pricing.ProductIdentitiesForm
  ): AxiosPromise<void> {
    return this.put(
      `/campaigns/${campaignId}/conditions/${conditionId}/products/identities`,
      productIdentities,
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }

  importProductsIntoEffect(
    campaignId: string,
    effectId: string,
    productIdentities: Pricing.ProductIdentitiesForm
  ): AxiosPromise<Pricing.ProductsImportIntoEffectResultRestView> {
    return this.put(
      `/campaigns/${campaignId}/effects/${effectId}/products/identities`,
      productIdentities,
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }

  importAwardsIntoEffect(
    campaignId: string,
    effectId: string,
    awards: Pricing.AwardsProductsReplaceForm[]
  ): AxiosPromise<Pricing.AwardReplaceResultRestView> {
    return this.put(
      `/campaigns/${campaignId}/effects/${effectId}/awards`,
      awards,
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }

  exportAwardsFromEffect(
    campaignId: string,
    effectId: string
  ): AxiosPromise<string> {
    return this.get(
      `/campaigns/${campaignId}/effects/${effectId}/awards/export`,
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }

  getManualCampaigns(
    campaignCalculationId: string
  ): AxiosPromise<Pricing.AvailableCampaignRestView> {
    return this.get(
      `/campaigns/manually-by-calculation/${campaignCalculationId}`,
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }
}
